<template>
  <div>
    <v-container>
      <v-sheet
        color="transparent"
      >
        <h2>Travels</h2>
        <p>Coming soon...</p>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Travels',
};
</script>

<style lang="scss">

</style>
